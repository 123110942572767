.BlogDetails_Box1 {
  width: 70%;
  padding: 15px;
}
.BlogDetails_Box2 {
  width: 30%;
  padding: 15px;
}

@media screen and (max-width: 600px) {
  .BlogDetails_Box1,
  .BlogDetails_Box2 {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .BlogDetails_Container {
    padding-left: 116px;
    padding-right: 116px;
  }
}

.BlogDetails_ImgContainer img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  border-radius: 12px ;
}
.BlogDetails_ImgAds{
  filter: brightness(80%);
}
.BlogDetails_ImgAdsGlass{
  backdrop-filter: blur(10px);
}
 

.BlogDetailsImgContainer{
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

 
