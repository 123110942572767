@font-face {
  font-family: "Poppins Regular";
  src: url("../src/Assets/Fonts/Poppins/Poppins-Regular.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins Thin";
  src: url("../src/Assets/Fonts/Poppins/Poppins-Thin.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "FontHelper.FontMedium";
  src: url("../src/Assets/Fonts/Poppins/Poppins-Medium.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins Light";
  src: url("../src/Assets/Fonts/Poppins/Poppins-Light.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins Poppins-ExtraLight";
  src: url("../src/Assets/Fonts/Poppins/Poppins-ExtraLight.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("../src/Assets/Fonts/Poppins/Poppins-SemiBold.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../src/Assets/Fonts/Poppins/Poppins-Bold.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../src/Assets/Fonts/Poppins/Poppins-ExtraBold.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins Black";
  src: url("../src/Assets/Fonts/Poppins/Poppins-Black.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-Medium";
  src: url("../src/Assets/Fonts/Nutino/Nunito-Medium.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-Black";
  src: url("../src/Assets/Fonts/Nutino/Nunito-Black.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-BlackItalic";
  src: url("../src/Assets/Fonts/Nutino/Nunito-BlackItalic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-Bold";
  src: url("../src/Assets/Fonts/Nutino/Nunito-Bold.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-BoldItalic";
  src: url("../src/Assets/Fonts/Nutino/Nunito-BoldItalic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-ExtraBold";
  src: url("../src/Assets/Fonts/Nutino/Nunito-ExtraBold.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-ExtraBoldItalic";
  src: url("../src/Assets/Fonts/Nutino/Nunito-ExtraBoldItalic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-ExtraLight";
  src: url("../src/Assets/Fonts/Nutino/Nunito-ExtraLight.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-ExtraLightItalic";
  src: url("../src/Assets/Fonts/Nutino/Nunito-ExtraLightItalic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-Italic";
  src: url("../src/Assets/Fonts/Nutino/Nunito-Italic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-Light";
  src: url("../src/Assets/Fonts/Nutino/Nunito-Light.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-LightItalic";
  src: url("../src/Assets/Fonts/Nutino/Nunito-LightItalic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-Regular";
  src: url("../src/Assets/Fonts/Nutino/Nunito-Regular.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-SemiBold";
  src: url("../src/Assets/Fonts/Nutino/Nunito-SemiBold.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Nunito-SemiBoldItalic";
  src: url("../src/Assets/Fonts/Nutino/Nunito-SemiBoldItalic.ttf");
  font-style: normal;
  font-display: block;
}
 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito-Regular", Arial, Helvetica, sans-serif !important;
  text-transform: unset !important;
  letter-spacing: "0px !important";
}
.css-ujobxp-MuiTypography-root {
  letter-spacing: "0px !important";
}

/* Will Make Header Sticky on Scroll */
.is-sticky {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 999 !important;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%) !important;
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.react-multi-carousel-item.react-multi-carousel-item--active {
  opacity: 1 !important;
}

/* .bg-salmon {
  background-color: #dc0053 !important;
  color: white !important;
}

.remove-salmon {
  background-color: #fff !important;
  color: 000 !important;
} */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --pink: #da1a5d;
  --blue: #457abd;
  --white: #ffffff;
  --button-secondar-border-color: #d0d5dd;
  --button-border-radius: 8px;
  --text1-color: #344054;
  --text2-color: #000;
  --text3-color: #e4e7ec;
  --text4-color: #101828;
  --icon-radius: 40px;
  --icon-bg-color: #fff3f5;
  --bt-font-size: 16px;
  --transitons: 0.2s;
}

#root {
  overflow-x: hidden;
  width: 100%;
}

header {
  background: #fcfcfd;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background: #fcfcfd;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
}

a {
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  color: var(--pink);
  cursor: pointer;
}

button {
  cursor: pointer;
}

button:hover,
button:active,
button:focus {
  border: none;
  outline: none;
}

.pos-rel {
  position: relative;
  padding: 0 5px;
}

.pos-abs {
  position: absolute;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.content-center {
  justify-content: center;
}

.content-between {
  justify-content: space-between;
}

.content-around {
  justify-content: space-around;
}

.header-container {
  padding: 15px 7vw;
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 33;
}

.mobiotics-logo img {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* @media (max-width: 376px){
  .banner {
    height: 98vh !important;
}



} */

@media (max-width: 376px) {
  #logoImage {
    /* width: 50vw !important; */
  }

  /* .css-19rsije {
    margin-left: -4rem !important;
  }
   */
  #TabNamesWrapper {
    height: 100% !important;
  }
  .banner {
    height: 100% !important;
    object-fit: cover;
  }

  .css-1w4df7h {
    width: 87% !important;
    left: 7% !important;
  }

  /* career */
  /* .css-1ylvst7 {
    margin-top: 110px !important;
  } */

  .css-1s7zm16 img {
    display: none;
  }

  .css-1f24mji {
    min-width: 85vw !important;
    margin-left: 100px !important;
    justify-content: flex-start !important;
    padding: 10px !important;
  }

  .css-bskktm-MuiTypography-root {
    width: 60vw !important;
  }

  /* .css-s75p5f{ */
    /* padding-top: 0px !important; */
  /* } */

  /* section banners */
  .css-61h0vg,.css-11ajqkk,.css-1l4315y,.css-f3a4e8 {
    min-height: 100% !important;
   max-height: 100% !important;
  }
  .css-61h0vg {
     min-height: 100%; 
     max-height: 100%;
  }

  /* .css-11ajqkk #heroOverlay {
    height: 0vh !important;
} */
.css-10qpa1v,.css-1l4315y  {
  height: 100% !important;
}

#HeroSecrtionContainer {
    min-height: 100% !important;
    max-height: 100% !important;
    height: 100% !important;
}

.css-f3a4e8{
  min-height: 100% !important;
  max-height: 100% !important;
}
.css-1j4ts36 {
  margin-top: 72px !important;
}
.css-1tx4lpd,.css-ehly4u,.css-mo22y3,.css-az1895,.css-s4xjwj{
  height: 100% !important;
}
/* brand section */


.css-1m6nsm4 img {
  max-width: 94% !important;
}

.css-1ys3kpb {
  margin-top: 25px !important;
}
/* indutries we cater to */
.css-1941xma-MuiTypography-root {
  font-size: 28px !important;
}

.css-8vy6sb {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.css-1nzfa27 {
  margin-top: 0px !important;
}
#TabNamesWrapper>div>div>div {
  justify-content: center !important;
}
.css-1pvgp33-MuiTypography-root,.css-7hbato {
  padding-top: 1rem;
}

.css-xi606m{
  margin-top: -30px !important;
}
#TabNamesWrapper>div>div>div>button {
  border-left: 2px solid rgb(223 166 163);
  border-right: 2px solid rgb(223 166 163);
}
.css-1t0b1eq {
  min-width: 85% !important;
  width: 85% !important;
}
.css-19kzrtu {
  padding: 0px !important;
}

.css-13v9sje{
  width: 70vw !important;
}

.imageBackgroundMobile{
  margin: 0 50% 0 8%;
  width: 86% !important;
}

.careerCardHeader{
  width: 136% !important;
}

.careerCardPara, .careerDescription{
  height: 100% !important;
  padding-bottom: 15rem !important;
}

}
@media (min-width: 377px) and (max-width: 500px) {

  .imageBackgroundMobile{
    margin: 0 50% 0 5%;
    width: 92% !important;
  }
  .banner {
    height: 100% !important;
    object-fit: cover;
  }

  #logoImage {
    /* width: 50vw !important; */
    height: 9vh !important;
  }

  .css-1w4df7h {
    /* max-width: 145px!important; */
    left: 10% !important;
  }

  /* .css-19rsije {
  margin-left: -4rem !important;
} */

  #TabNamesWrapper {
    height: 100% !important;
  }

  /* career */
  /* .css-1ylvst7 {
    margin-top: 110px !important;
  } */

  .css-1s7zm16 img {
    display: none;
  }

  .css-1f24mji {
    min-width: 85vw !important;
    margin-left: 100px !important;
    justify-content: flex-start !important;
    padding: 10px !important;
  }

  .css-bskktm-MuiTypography-root {
    width: 35vw !important;
  }
  /* .css-s75p5f{ */
    /* padding-top: 0px !important; */
  /* } */

  .css-61h0vg,.css-11ajqkk,.css-1l4315y,.css-f3a4e8 {
    min-height: 100% !important;
   max-height: 100% !important;
  }
  .css-61h0vg {
     min-height: 100%; 
     max-height: 100%;
  }

  /* .css-11ajqkk #heroOverlay {
    height: 0vh !important;
} */
.css-10qpa1v,.css-1l4315y  {
  height: 100% !important;
}

#HeroSecrtionContainer {
    min-height: 100% !important;
    max-height: 100% !important;
    height: 100% !important;
}

.css-f3a4e8{
  min-height: 100% !important;
  max-height: 100% !important;
}
.css-1j4ts36 {
  margin-top: 72px !important;
}
.css-1tx4lpd,.css-ehly4u,.css-mo22y3,.css-az1895,.css-s4xjwj{
  height: 100% !important;
}
/* brand section */


.css-1m6nsm4 img {
    max-width: 95% !important;
}
.css-1ys3kpb {
  margin-top: 25px !important;
}
/* indutries we cater to */
.css-1941xma-MuiTypography-root {
  font-size: 28px !important;
}

.css-8vy6sb {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.css-1nzfa27 {
  margin-top: 0px !important;
}
.css-1pvgp33-MuiTypography-root,.css-7hbato {
  padding-top: 1rem;
}

.css-xi606m{
  margin-top: -30px !important;
}
#TabNamesWrapper>div>div>div>button {
  border: 2px solid rgb(223 166 163);

}
.css-1t0b1eq {
  min-width: 90% !important;
  width: 90% !important;
}

.css-19kzrtu {
  padding: 0px !important;
}
.css-13v9sje{
  width: 70vw !important;
}
.careerCardHeader{
  width: 136% !important;
}

.careerCardPara,.careerDescription{
  height: 100% !important;
}
}

@media (min-width: 501px) and (max-width: 899px) {
  .header-container {
    margin-right: 6rem !important;
  }
  .mobiotics-logo img {
    width: 155px;
  }
  .banner {
    height: 100% !important;
    object-fit: cover;
  }

  .css-5vg7c {
    bottom: 26% !important;
  }

  #logoImage {
    /* left: 52% !important; */
    /* height: 7rem !important; */
    margin-left: -2rem !important;
  }
  /* career */
  .css-1ylvst7 {
    margin-bottom: 40px !important;
  }

  .css-1s7zm16 img {
    display: none;
  }

  .css-1f24mji {
    min-width: 40vw !important;
    margin-left: 180px !important;
  }

  .css-bskktm-MuiTypography-root {
    width: 35vw !important;
  }

  .css-o561i0 {
    height: 100% !important;
  }

  /* highlights of vlive */
  .css-ftaima {
    aspect-ratio: 0 !important;
  }
  /* brands image */
  .css-1m6nsm4 img {
    max-width: 190px !important;
    object-fit: contain;
  }
  /* .css-s75p5f{
    padding-top: 0px !important;
  } */
  .css-61h0vg,.css-11ajqkk,.css-1l4315y,.css-f3a4e8 {
    min-height: 100% !important;
   max-height: 100% !important;
  }
  .css-61h0vg {
     min-height: 100%; 
     max-height: 100%;
  }

  /* .css-11ajqkk #heroOverlay {
    height: 0vh !important;
} */
.css-10qpa1v,.css-1l4315y  {
  height: 100% !important;
}

#HeroSecrtionContainer {
    min-height: 100% !important;
    max-height: 100% !important;
    height: 100% !important;
}

.css-f3a4e8{
  min-height: 100% !important;
  max-height: 100% !important;
}
.css-1j4ts36 {
  margin-top: 72px !important;
}
.css-1tx4lpd,.css-ehly4u,.css-mo22y3,.css-az1895,.css-s4xjwj{
  height: 100% !important;
}

.css-gmicdv {
  height: 100%;
}
.css-1pvgp33-MuiTypography-root,.css-7hbato {
  padding-top: 2rem;
}

.css-xi606m{
  margin-top: -30px !important;
}

}

@media (min-width: 900px) and (max-width: 1200px) {
  .css-8sdq3u {
    max-width: 100% !important;
  }
  .banner {
    height: 100% !important;
    object-fit: cover;
  }

  .header-container {
    margin-right: 7rem !important;
  }

  .header-container div a {
    width: 9rem;
  }

  .header-container div a button {
    font-size: 12px;
  }
  .header-container div div {
    /* width: 7rem; */
    font-size: 10px;
  }
  .mobiotics-logo img {
    width: 117px;
    margin-right: 35px;
  }

  /* .css-19rsije {
  max-width: 20vw !important;
} */
  /* other banners */

  /* .css-c2jjd0 {
  min-height: 95vh !important;
} */

  #HeroSecrtionContainer {
    min-height: 60vh !important;
    margin-top: 11rem;
  }
  #heroOverlay {
    height: 61vh !important;
  }
  .css-cfk5cs-MuiTypography-root {
    width: 110vw;
    margin-top: 2rem !important;
    /* padding-left: 100px !important; */
    padding-right: 130px !important;
  }

  .css-5vg7c {
    bottom: 22% !important;
  }
  /* Career */
  /* .css-o561i0 {
  height: 105vh !important;
} */

  .css-1s7zm16 img {
    width: 22rem;
    height: 35rem;
  }

  .css-1f24mji {
    margin-left: 0 !important;
    min-width: 36% !important;
  }

  .css-bskktm-MuiTypography-root {
    width: 20vw !important;
  }

  .css-v6wxhw {
    min-height: 0 !important;
  }

  .css-o561i0 {
    height: 0 !important;
  }
  .css-1wcrqwt {
    width: auto !important;
  }
  .css-1pvgp33-MuiTypography-root,.css-7hbato {
    padding-top: 2rem;
  }

  .css-xi606m{
    margin-top: -30px !important;
  }
}
/* banner arrow button */

@media (min-width: 1201px) and (max-width: 1350px) {
  .css-5vg7c {
    bottom: 9% !important;
  }

  #HeroSecrtionContainer {
    margin-top: 7rem;
  }

  .imageBackgroundMobile{
    /* margin: 0 50% 0 19%; */
    width: 60% !important;
  }

  .css-1f24mji {
    /* min-width: 40% !important; */
    margin-left: 0 !important;
  }

  /* banner images */
  .css-1m6nsm4 img {
    padding: 0 20px 0 20px;
    object-fit: contain;
  }

  .reviewer-info img {
    width: 180px;
  }

  .css-1vvnkgl {
    min-height: 17rem !important;
  }

  .css-1vvnkgl img {
    /* height: 15rem !important; */
    width: 90% !important;
  }

  .css-8vy6sb {
    padding-bottom: 0 !important;
  }

  /* .css-1hhsj02 {
  bottom: 75px !important;
} */

  /* hover industry card */
  .css-g8ctzj {
    /* height: 42.2vh; */
    width: 14.5rem;
  }

  .css-1hhsj02 {
    left: 0% !important;
    right: 10% !important;
  }

  /* heighlight section with button */
  .css-1uwbjea,
  .css-hx78ds,
  .css-eegekv {
    min-height: 100vh !important;
  }

  .css-521ihk,
  .css-1e4ka08-MuiTypography-root,
  .css-r9mqqi-MuiTypography-root {
    max-width: 100% !important;
  }

  .css-10s9g69-MuiTypography-root,
  .css-11v4p7a {
    max-width: 100% !important;
  }

  /* nav button */
  .header-container div a button {
    padding: 10px 10px !important;
  }

  .css-cfk5cs-MuiTypography-root {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .css-xj929e {
    width: 12rem !important;
  }

  /* highlight cards */
  /* .css-7xhjg5 {
    max-width: 30% !important;
    min-width: 30% !important;
  } */

  /* vlite mooc banner */
  .css-cfk5cs-MuiTypography-root {
    width: 110vw;
    margin-top: 2rem !important;
    /* padding-left: 100px !important; */
    /* padding-right: 130px !important; */
  }

  /* highlight cards */
  .css-7xhjg5 {
    width: 30% !important;
    max-width: 30% !important;
    min-width: 30% !important;
    aspect-ratio: 0 !important;

  }
  /* nav logo */
  .mobiotics-logo img {
    /* height: 50px; */

    margin-left: -28px;
    width: 100%;
  }

    /* bannner image below space */
    /* .css-s75p5f{
      padding-top: 0px !important;
    } */

    /* contact me mobie dropdown */
    .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
      top: 453px !important;
    }

    .css-odc04-MuiTypography-root {
      max-width: 80% !important;
    }

    
    .css-1pvgp33-MuiTypography-root,.css-7hbato {
      padding-top: 2rem;
    }

    .css-xi606m{
      margin-top: -30px !important;
    }

    .css-1wcrqwt {
      width: auto !important;
    }

    .careerCardContainer{
      padding-left: 0px !important;
    
    }

    .careerCard{
      margin-left: 0px !important;
    }

    .careerCardPara,.careerDescription{
      height: 100% !important;
      padding-bottom: 19rem !important;
    }

}

@media (min-width: 1350px) {
  /* industries we cater to */
  .css-1vvnkgl img {
    /* height: 15rem !important; */
    width: 98% !important;
  }
  .careerDescription{
    height: 100% !important;
    width: 19vw !important;
  }
  .css-g8ctzj {
    /* height: 42.2vh; */
    width: 16.9rem !important;
  }

  .css-1hhsj02 {
    margin-left: 0% !important;
    left: -3% !important;
  }

  /* vlite mooc banner */
  .css-cfk5cs-MuiTypography-root {
    width: 80vw;
    margin-top: 1rem !important;
    /* padding-left: 100px !important; */
    padding-right: 130px !important;
  }
  /* highlight cards */
  .css-7xhjg5 {
    max-width: 30% !important;
    min-width: 30% !important;
    aspect-ratio: 0 !important;
  }

  /* brand icons */
  .css-1m6nsm4 img {
    padding: 0 20px 0 20px;
    object-fit: contain;
  }

  /* nav logo */
  .mobiotics-logo img {
    /* height: 50px; */

    margin-left: -28px;
    width: 100%;
  }

  /* bannner image below space */
  /* .css-s75p5f{
    padding-top: 0px !important;
  } */

  
    /* banner arrow */
    .css-5vg7c {
      bottom: 9% !important;
    }

    .css-odc04-MuiTypography-root {
      max-width: 80% !important;
    }

    .css-1f24mji {
      aspect-ratio: auto !important;
    }
    .css-1wcrqwt {
      width: auto !important;
    }

    .css-1pvgp33-MuiTypography-root,.css-7hbato {
      padding-top: 2rem;
    }

    .css-xi606m{
      margin-top: -30px !important;
    }

    .careerCardContainer{
      padding-left: 0px !important;
    
    }
  
}
.mobiotics-link-box {
  display: grid;
  grid-template-columns: 50px auto;
  align-items: flex-start;
  padding: 20px 10px;
  width: 346px;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  /* border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; */
  transition: var(--transitons);
}

.mobiotics-link-box:hover {
  background-color: var(--pink);
  color: var(--white);
}

.mobiotics-link-box #OTTMenu > svg {
  max-width: 30px;
  transform: rotate(180deg);
}

.mobiotics-link-box:hover #OTTMenu > svg {
  fill: var(--white);
}

.mobiotics-link-subbox {
  height: 0;
}

.mobiotics-link-box .mobiotics-link-icon,
.mobiotics-link-box:hover .mobiotics-link-icon {
  width: 48px;
  height: 48px;
  border-radius: var(--icon-radius);
  background-color: var(--icon-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
}

.mobiotics-FlexIt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobiotics-link-box .mobiotics-link-texts {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  color: var(--text1-color);
  cursor: pointer;
}

.mobiotics-link-texts-name {
  font-size: 14px;
  cursor: pointer;
}

.mobiotics-link-texts-caption {
  font-size: 12px;
  cursor: pointer;
}

.mobiotics-link-box:hover .mobiotics-link-texts-name,
.mobiotics-link-box:hover .mobiotics-link-texts-caption {
  color: var(--white);
}

.mobiotics-link-title {
  position: relative;
  padding: 12px 20px;
  color: var(--text2-color);
  border-radius: var(--button-border-radius);
  transition: var(--transitons);
  cursor: pointer;
}

.mobiotics-activelink {
  background-color: var(--pink);
  color: var(--white);
}

.mobiotics-link-title:hover {
  background-color: var(--pink);
  color: var(--white);
}

.mobiotics-sublink-box {
  position: absolute;
  top: 100%;
  left: 0;
  /* transform: translateX(-50%); */
  padding: 20px 10px;
  background-color: var(--white);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
}

.button {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  transition: 0.2s;
  font-size: var(--bt-font-size);
}

/*=======secondary button=========*/
.button-secondary {
  background: var(--white);
  color: var(--text1-color);
  border: 1px solid var(--button-secondar-border-color);
}

.button-secondary:hover {
  background: var(--pink);
  color: var(--white);
  border: 1px solid var(--pink);
}

/*========primary button========*/
.button-primary {
  background: var(--pink);
  color: var(--white);
  border: 1px solid var(--pink);
  cursor: pointer;
}

.button-primary:hover {
  background: var(--white);
  color: var(--text1-color);
  border: 1px solid var(--button-secondar-border-color);
}

/*================*/
.shortdesc {
  color: #366eb4 !important;
  font-size: 19px !important;
  font-weight: bolder !important;
}

#HeroSecrtionContainer {
  margin-top: 6rem;
}

.css-vrk4dv {
  padding: 0px !important;
}


.css-1wcrqwt {
  width: auto !important;
}

