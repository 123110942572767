.banner{
    height:100vh;
}

.banner .css-1prp5tx{
    height:100vh !important;
}
.banner .css-q1nkyl {
    height:100vh !important;
}
.banner .css-167slej {
    height:100vh !important;
}

.banner .css-135ehjr{
    margin-top:0px !important;
}
.banner .css-kuwl9c {
    padding-left: 0px !important;
    padding-right: 80px;
    margin-right: 0px;
    left: 15%;
    position: absolute;
}