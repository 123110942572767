 
.CarrierDetails_Box1 {
  width: 60%;
  padding: 15px;
}
.CarrierDetails_Box2 {
  width: 40%;
  padding: 15px;
}

@media only screen and (min-width: 1200px) {
  .CarrierDetails_Container {
    padding-left: 116px;
    padding-right: 116px;
  }
}

@media screen and (max-width: 600px) {
  .CarrierDetails_Box1,
  .CarrierDetails_Box2 {
    width: 100%;
  }
}
