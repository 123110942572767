#TabNamesWrapper>div {
    min-width: 95% !important;
}

#TabNamesWrapper>div>div>div {
    justify-content: center !important;
    margin-top: 1rem;
}

#TabNamesWrapper>div>div .MuiTabs-indicator {
    display: none;
}

#TabNamesWrapper>div>div>div .Mui-selected {
    background-color: #da1a5d;
    color: #fff;
    font-weight: 800;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    #TabNamesWrapper {
        max-width: 85vw;
        padding: 0px 10px;
    }

    #TabNamesWrapper>div>div>div {
        overflow-x: auto;
    }

    #coverImage {
        margin-left: -50%;
        object-fit: cover;
    }

    #logoImage {
        margin-left: -28%;
        object-fit: cover;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    #TabNamesWrapper {
        max-width: 85vw;
        padding: 0px 10px;
    }

    #TabNamesWrapper>div>div>div {
        overflow-x: auto;
    }

    #coverImage {
        max-width: 95%;
        min-height: 180px;
        border-radius: 25px;
    }

    #logoImage {
        /* margin-left: 2%; */
        height: auto !important;
    }
}

@media (width: 768px) and (height: 1024px) {
    #coverImage {
        min-width: fit-content;
    }
}

  .divheight{
    position: relative;
    width: 100%;
    background: #F5F8FF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2% 5%;
  }
.css-s75p5f{
    padding-left: 0px !important;
    padding-right: 0px !important;
    text-align: center;
}

.css-gg1bs2 {
    padding: 0 40px 40px 40px !important;
}