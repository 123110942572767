/* hide radio buttons, but still expose them to screen readers */
#ShowBanner3d input {
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}

#ShowBanner3d .selector {
  display: grid;
  grid-template-areas:
    'happy lovers Bird'
    'pick  pick   pick';
}

/* the cards and toggle buttons are actually fat labels that can be clicked to set radio buttons */
#ShowBanner3d label {
  display: block;
  cursor: pointer;
}

#ShowBanner3d .happy-card,
#ShowBanner3d .lovers-card,
#ShowBanner3d .Bird-card {
  position: relative;
  /* for iOS */
  width: 100%;
  padding: 20px;
  color: #586875;
  background-color: #f4f7f7;
  box-shadow: 0 10px 20px #0006;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  /* important to use a symmetrical timing function (linear also works) */
  animation-fill-mode: forwards;
}

#ShowBanner3d .happy-card {
  grid-area: happy;
  margin-right: -10px;
  /* nudge closer to lover's card */

  /* these values are used to animate via translateX */
  --swing: -25px;
  --overlap: 25px;
}

#ShowBanner3d .lovers-card {
  grid-area: lovers;
  margin-left: -10px;
  /* nudge closer to happy card */

  /* these values are used to animate via translateX */
  --swing: 25px;
  --overlap: -25px;
}


#ShowBanner3d .Bird-card {
  grid-area: Bird;
  margin-left: -10px;
  /* nudge closer to happy card */

  /* these values are used to animate via translateX */
  --swing: 25px;
  --overlap: -25px;
}

/* when a radio button is set, swing the corresponding card fowards */
#ShowBanner3d #happy-radio:checked~.selector .happy-card,
#ShowBanner3d #lovers-radio:checked~.selector .lovers-card,
#ShowBanner3d #Bird-radio:checked~.selector .Bird-card {
  animation-name: swing-forwards;
}

@keyframes swing-forwards {
  0% {
    z-index: 1;
    /* start behind other card */
    transform: scale(0.9);
  }

  50% {
    transform: translateX(var(--swing));
  }

  100% {
    z-index: 2;
    /* end in front of other card */
    transform: translateX(var(--overlap)) scale(1.1);
  }
}

/* when a radio button is set, swing the opposite card backwards */
#ShowBanner3d #happy-radio:checked~.selector .lovers-card,
#ShowBanner3d #lovers-radio:checked~.selector .happy-card,
#ShowBanner3d #Bird-radio:checked~.selector .Bird-card {
  animation-name: swing-backwards;
}

@keyframes swing-backwards {
  0% {
    z-index: 2;
    /* start in front of other card */
    transform: translateX(var(--overlap)) scale(1.1);
  }

  50% {
    transform: translateX(var(--swing));
  }

  100% {
    z-index: 1;
    /* end behind other card */
    transform: scale(0.9);
  }
}

#ShowBanner3d .happy-pick,
#ShowBanner3d .lovers-pick,
#ShowBanner3d .Bird-pick {
  grid-area: pick;
  margin: 50px auto 0;
  padding: 15px;
  border: 1px solid #f4f7f7;
  border-radius: 9999px;
  background-color: #b46d73;
  font-size: 1.25rem;
  -webkit-user-select: none;
  user-select: none;
}

#ShowBanner3d .happy-pick::before,
#ShowBanner3d .lovers-pick::after,
#ShowBanner3d .Bird-pick::before {
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-weight: bold;
}

#ShowBanner3d .happy-pick::before {
  margin-right: 10px;
  content: '\f0a8';
  /* fa-arrow-circle-left */
}

#ShowBanner3d .lovers-pick::after {
  margin-left: 10px;
  content: '\f0a9';
  /* fa-arrow-circle-right */
}

#ShowBanner3d .Bird-pick::after {
  margin-left: 10px;
  content: '\f0a9';
  /* fa-arrow-circle-right */
}

/* hide happy toggle button if happy is currently selected */
#ShowBanner3d #happy-radio:checked~.selector .happy-pick {
  display: none;
}

/* hide lover's toggle button if lover's is currently selected */
#ShowBanner3d #lovers-radio:checked~.selector .lovers-pick {
  display: none;
}

/* hide lover's toggle button if lover's is currently selected */
#ShowBanner3d #Bird-radio:checked~.selector .Bird-pick {
  display: none;
}

/* increase overlap and swing distance on smaller screens */
@media (max-width: 560px) {
  .happy-card {
    margin-right: -85px;
    --swing: -100px;
  }

  .lovers-card {
    margin-left: -85px;
    --swing: 100px;
  }

  .Bird-card {
    margin-left: -85px;
    --swing: -100px;
  }
}