/* hide scrollbar but allow scrolling */
#categoriesContianer {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-x: scroll;
}

#categoriesContianer::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

#category {
  margin: auto;
}

/* #categoriesContianer .react-multi-carousel-track {
  width: 100vw;
  height: auto;
  display: flex;
  list-style-type: none;
} */
/* tried to make categories scroll on desktop */
/* #categoriesContianer{
  width: 100%;
  scroll-snap-type: x mandatory;
  scroll-padding:  25px;
  column-gap: 20px;
}
#category {
  scroll-snap-align: start;
} */