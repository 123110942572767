.itemA {
    grid-area: itemA;
}

.item {
    grid-area: itemB;
}

.itemc {
    grid-area: itemC;
}

.itemD {
    grid-area: itemD;
}

.itemE {
    grid-area: itemE;
}

.itemF {
    grid-area: itemF;
}

#Custom-Submenu .MuiList-root.MuiList-padding.MuiMenu-list {
    display: grid;
    min-width: max-content;
    max-height: max-content;
    /* grid-template-columns: 50% 50%; */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    /* grid-template-areas:
        "itemA itemB"
        "itemC itemD"
        "itemE item"; */
}

/* #demo-customized-button #basic-button :active{ 
    background-color: #dc0053;
    color: #fff !important;
}
#demo-customized-button #basic-button :hover{ 
    background-color: #dc0053;
    color: #fff !important;
} */
/* #basic-button:hover{ 
    background-color: #dc0053 !important;
    color: #fff !important;
}
#basic-button:active{ 
    background-color: #dc0053 !important;
    color: #fff !important;
}
.demo-customized-button:active #basic-button {
    background-color: #dc0053 !important;
    color: #fff !important;
}
#demo-customized-button:hover {
    background-color: #fff !important;
}
.css-1q8h5hz-MuiButtonBase-root-MuiButton-root:active {
    background-color: #dc0053 !important;
    color: #fff !important;
} */
/* .css-145ztm3-MuiButtonBase-root-MuiButton-root{
    background-color: #fff !important;
    color: #fff !important;
}
.MuiBox-root{
    color: #000!important;
}
.css-145ztm3-MuiButtonBase-root-MuiButton-root:active {
background-color:#da1a5d !important;
}
#basic-button[ aria-expanded="true"]{
background-color:#da1a5d !important;
color: #fff !important;
} */

@media (min-height: 650px),
screen and (orientation: portrait) {
    #Custom-Submenu .MuiList-root.MuiList-padding.MuiMenu-list {
        grid-template-columns: repeat(1, 1fr);

    }
}

@media screen and (min-width: 30em) and (orientation: landscape) {
    #Custom-Submenu .MuiList-root.MuiList-padding.MuiMenu-list {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "itemA itemB"
            "itemC itemD"
            "itemE item";
    }
}

.itemA.MuiBox-root {
    width: 100%;
}

.MuiAccordion-region .css-0:last-child {
    border: none;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    background-color: none !important;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none !important;
    position: relative;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
    width: 100%;
}

.MuiAccordion-region .css-0 {
    display: flex;
    justify-content: left;
    padding: 13px 8px;
    border-bottom: 1px solid #ed9b9b;
}

.MuiAccordion-region .css-0:hover {
    background-color: #ed9b9b;
}

.css-s8vbhp {
    margin-left: 0px !important;

}

.MuiAccordion-region .css-15v22id-MuiAccordionDetails-root {
    padding: 0 !important;
    background-color: #e6b2b240;
}

.MuiAccordion-region .css-17nhtmm-MuiTypography-root {
    margin-top: 12px !important;
    padding-left: 10px;
}

.customcolor .MuiAccordionSummary-root {
    background-color: #dc0053;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    color: white;
}

.customcolor .text-Title {
    color: white !important;
    padding-left: 5px;
}

.customcolor .text-Caption {
    color: white !important;
    padding-left: 5px;
}

#Custom-Submenu .MuiPaper-elevation {
    display: none;
}


.set-position {
    top: 92,
}